import { Shadow } from './shadow'

/**
 * Represents the document backup in the differential synchronization algorithm
 */
export class Backup<T> {
  /**
   * The content held by this backup document
   */
  private content: T
  /**
   * The local revision of the backup
   */
  private localRev: number

  constructor(content: T) {
    this.content = content
    this.localRev = 0
  }

  /**
   * Get this backup document content
   *
   * @returns the content held by this backup document
   */
  public getContent(): T {
    return this.content
  }

  /**
   * Set this backup document content
   *
   * @param content the content to set to this backup
   * @returns {this}
   */
  public setContent(content: T): Backup<T> {
    this.content = JSON.parse(JSON.stringify(content))
    return this
  }

  /**
   * Set the local rev for this backup document
   *
   * @param localRev the local rev to set for this backup
   * @returns {this}
   */
  public setLocalRev(localRev: number): Backup<T> {
    this.localRev = localRev
    return this
  }

  /**
   * Get this backup local revision
   *
   * @returns the local revision
   */
  public getLocalRev(): number {
    return this.localRev
  }

  /**
   * Copy the content from this backup to a shadow. When this happens
   * the shadow local rev is set to this backup local rev
   * @param shadow the shadow document to copy the content to
   * @returns {this}
   */
  public copyTo(shadow: Shadow<T>): Backup<T> {
    shadow.setContent(this.content)
    return this
  }
}
