import { EditStack } from './lib/edit.stack'

export enum SavedStatus {
  IS_SAVED = 'is_saved',
  SAVING = 'is_saving',
}

export interface Message {
  /**
   * The diff sync unique reference
   */
  ref: string
  /**
   * The stack of edits
   */
  editStack: EditStack
  /**
   * When the server ack
   */
  isSaved?: SavedStatus.IS_SAVED | SavedStatus.SAVING
}
